import { Col, Container, Image, Nav, Row, Tab } from "react-bootstrap"
import { Link, withI18next } from "gatsby-plugin-i18next"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { I18n } from "react-i18next"
import Layout from "../components/layout"
import React from "react"
import SEO from "../components/seo"
import agriculture from "../images/mctic/services/agriculture.jpg"
import { faCogs } from "@fortawesome/free-solid-svg-icons"
import finance from "../images/mctic/services/finance.jpg"
import gender from "../images/mctic/services/gender.jpg"
import { graphql } from "gatsby"
import marketing from "../images/mctic/services/marketing.jpg"

class McticServices extends React.Component {
  render() {
    return (
      <I18n>
        {t => (
          <Layout pageContext={this.props.pageContext}>
            <SEO title="" />
            <div className="section bg-dark-blue mt-4">
              <Container className="height-md-30">
                <Row>
                  <Col md="8" lg="7" xl="6">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb p-0 bg-dark-blue bg-transparent">
                        <li className="breadcrumb-item">
                          <Link to="/" className="text-white">
                            {t("home")}
                          </Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/mctic-home" className="text-white">
                            {t("mctic")}
                          </Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/mctic-services" className="text-white">
                            {t("services")}
                          </Link>
                        </li>
                      </ol>
                    </nav>
                  </Col>
                  <Col md="12">
                    <h1 className="display-5 text-white">
                      {t("services")} <FontAwesomeIcon icon={faCogs} />
                    </h1>
                  </Col>
                </Row>
              </Container>
            </div>
            <Container>
              <div className="my-5">
                <h2 className="mb-4">{t("ac_best_practice")}</h2>
                <Tab.Container defaultActiveKey="home">
                  <div className="tab-container">
                    <Nav fill className="tab-nav">
                      <Nav.Item>
                        <Nav.Link eventKey="home">
                          {t("ac_best_practice")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="training">{t("training")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="research">{t("research")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="resources">
                          {t("resources")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="home">
                        <Container>
                          <Row>
                            <Col lg={8}>
                              <p>{t("ac_best_practice_desc")}</p>
                            </Col>
                            <Col lg={4}>
                              <Image
                                src={agriculture}
                                rounded
                                className="tab-image"
                              />
                            </Col>
                          </Row>
                        </Container>
                      </Tab.Pane>
                      <Tab.Pane eventKey="training">
                        <h4>{t("ac_training_desc")}</h4>
                        <h5>{t("animal_health")}</h5>
                        <p>{t("animal_health_desc")}</p>
                        <ul>
                          <li>
                            <p>{t("animal_health_desc_1")}</p>
                          </li>
                          <li>
                            <p>{t("animal_health_desc_2")}</p>
                          </li>
                          <li>
                            <p>{t("animal_health_desc_3")}</p>
                          </li>
                          <li>
                            <p>{t("animal_health_desc_4")}</p>
                          </li>
                          <li>
                            <p>{t("animal_health_desc_5")}</p>
                          </li>
                          <li>
                            <p>{t("animal_health_desc_6")}</p>
                          </li>
                          <li>
                            <p>{t("animal_health_desc_7")}</p>
                          </li>
                        </ul>
                        <h5>{t("breeding_and_tech")}</h5>
                        <p>{t("breeding_and_tech_desc")}</p>
                        <ul>
                          <li>
                            <p>{t("breeding_and_tech_desc_1")}</p>
                          </li>
                          <li>
                            <p>{t("breeding_and_tech_desc_2")}</p>
                          </li>
                          <li>
                            <p>{t("breeding_and_tech_desc_3")}</p>
                          </li>
                          <li>
                            <p>{t("breeding_and_tech_desc_4")}</p>
                          </li>
                          <li>
                            <p>{t("breeding_and_tech_desc_5")}</p>
                          </li>
                          <li>
                            <p>{t("breeding_and_tech_desc_6")}</p>
                          </li>
                          <li>
                            <p>{t("breeding_and_tech_desc_7")}</p>
                          </li>
                          <li>
                            <p>{t("breeding_and_tech_desc_8")}</p>
                          </li>
                        </ul>
                        <h5>{t("pasture_and_fodder")}</h5>
                        <p>{t("pasture_and_fodder_desc")}</p>
                        <ul>
                          <li>
                            <p>{t("pasture_and_fodder_desc_1")}</p>
                          </li>
                          <li>
                            <p>{t("pasture_and_fodder_desc_2")}</p>
                          </li>
                          <li>
                            <p>{t("pasture_and_fodder_desc_3")}</p>
                          </li>
                          <li>
                            <p>{t("pasture_and_fodder_desc_4")}</p>
                          </li>
                        </ul>
                      </Tab.Pane>
                      <Tab.Pane eventKey="research">
                        <h4>{t("ac_research_desc")}</h4>
                        <h5>{t("livestock_fodder")}</h5>
                        <p>{t("livestock_fodder_desc")}</p>
                        <h5>{t("condensing_pastureland")}</h5>
                        <p>{t("condensing_pastureland_desc")}</p>
                        <h5>{t("yield_and_productivity")}</h5>
                        <p>{t("yield_and_productivity_desc")}</p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="resources">
                        <h4>{t("ac_resources_desc")}</h4>
                        <ul>
                          <li>
                            <p>{t("ac_resources_desc_1")}</p>
                          </li>
                          <li>
                            <p>{t("ac_resources_desc_2")}</p>
                          </li>
                          <li>
                            <p>{t("ac_resources_desc_3")}</p>
                          </li>
                          <li>
                            <p>{t("ac_resources_desc_4")}</p>
                          </li>
                          <li>
                            <p>{t("ac_resources_desc_5")}</p>
                          </li>
                          <li>
                            <p>{t("ac_resources_desc_6")}</p>
                          </li>
                          <li>
                            <p>{t("ac_resources_desc_7")}</p>
                          </li>
                          <li>
                            <p>{t("ac_resources_desc_8")}</p>
                          </li>
                          <li>
                            <p>{t("ac_resources_desc_9")}</p>
                          </li>
                          <li>
                            <p>{t("ac_resources_desc_10")}</p>
                          </li>
                          <li>
                            <p>{t("ac_resources_desc_11")}</p>
                          </li>
                          <li>
                            <p>{t("ac_resources_desc_12")}</p>
                          </li>
                          <li>
                            <p>{t("ac_resources_desc_13")}</p>
                          </li>
                        </ul>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </div>
              <div className="my-5">
                <h2 className="mb-4">{t("gender_equality")}</h2>
                <Tab.Container defaultActiveKey="home">
                  <div className="tab-container">
                    <Nav fill className="tab-nav">
                      <Nav.Item>
                        <Nav.Link eventKey="home">
                          {t("gender_equality")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="training">{t("training")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="resources">
                          {t("resources")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="home">
                        <Container>
                          <Row>
                            <Col lg={4}>
                              <Image
                                src={gender}
                                rounded
                                className="tab-image"
                              />
                            </Col>
                            <Col lg={8}>
                              <p>{t("gender_equality_desc")}</p>
                            </Col>
                          </Row>
                        </Container>
                      </Tab.Pane>
                      <Tab.Pane eventKey="training">
                        <h4>{t("ge_training_desc")}</h4>
                        <h5>{t("understanding_gender")}</h5>
                        <p>{t("understanding_gender_desc")}</p>
                        <ul>
                          <li>
                            <p>{t("understanding_gender_desc_1")}</p>
                          </li>
                          <li>
                            <p>{t("understanding_gender_desc_2")}</p>
                          </li>
                          <li>
                            <p>{t("understanding_gender_desc_3")}</p>
                          </li>
                        </ul>
                        <h5>{t("gender_based_violence")}</h5>
                        <p>{t("gender_based_violence_desc")}</p>
                        <ul>
                          <li>
                            <p>{t("gender_based_violence_desc_1")}</p>
                          </li>
                          <li>
                            <p>{t("gender_based_violence_desc_2")}</p>
                          </li>
                          <li>
                            <p>{t("gender_based_violence_desc_3")}</p>
                          </li>
                        </ul>
                        <h5>{t("gender_mainstreaming")}</h5>
                        <p>{t("gender_mainstreaming_desc")}</p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="resources">
                        <h4>{t("ge_resources_desc")}</h4>
                        <ul>
                          <li>
                            <p>{t("ge_resources_desc_1")}</p>
                          </li>
                          <li>
                            <p>{t("ge_resources_desc_2")}</p>
                          </li>
                          <li>
                            <p>{t("ge_resources_desc_3")}</p>
                          </li>
                        </ul>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </div>
              <div className="my-5">
                <h2 className="mb-4">{t("mc_dev")}</h2>
                <Tab.Container defaultActiveKey="home">
                  <div className="tab-container">
                    <Nav fill className="tab-nav">
                      <Nav.Item>
                        <Nav.Link eventKey="home">{t("mc_dev")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="training">{t("training")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="research">{t("research")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="resources">
                          {t("resources")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="home">
                        <Container>
                          <Row>
                            <Col lg={8}>
                              <p>{t("mc_dev_desc")}</p>
                            </Col>
                            <Col lg={4}>
                              <Image
                                src={marketing}
                                rounded
                                className="tab-image"
                              />
                            </Col>
                          </Row>
                        </Container>
                      </Tab.Pane>
                      <Tab.Pane eventKey="training">
                        <h4>{t("mc_dev_training_desc")}</h4>
                        <h5>{t("improving_cc")}</h5>
                        <ul>
                          <li>
                            <p>{t("improving_cc_desc_1")}</p>
                          </li>
                          <li>
                            <p>{t("improving_cc_desc_2")}</p>
                          </li>
                          <li>
                            <p>{t("improving_cc_desc_3")}</p>
                          </li>
                          <li>
                            <p>{t("improving_cc_desc_4")}</p>
                          </li>
                          <li>
                            <p>{t("improving_cc_desc_5")}</p>
                          </li>
                          <li>
                            <p>{t("improving_cc_desc_6")}</p>
                          </li>
                          <li>
                            <p>{t("improving_cc_desc_7")}</p>
                          </li>
                          <li>
                            <p>{t("improving_cc_desc_8")}</p>
                          </li>
                          <li>
                            <p>{t("improving_cc_desc_9")}</p>
                          </li>
                          <li>
                            <p>{t("improving_cc_desc_10")}</p>
                          </li>
                        </ul>
                        <h5>{t("developing_cps")}</h5>
                        <ul>
                          <li>
                            <p>{t("developing_cps_desc_1")}</p>
                          </li>
                          <li>
                            <p>{t("developing_cps_desc_2")}</p>
                          </li>
                          <li>
                            <p>{t("developing_cps_desc_3")}</p>
                          </li>
                          <li>
                            <p>{t("developing_cps_desc_4")}</p>
                          </li>
                          <li>
                            <p>{t("developing_cps_desc_5")}</p>
                          </li>
                          <li>
                            <p>{t("developing_cps_desc_6")}</p>
                          </li>
                          <li>
                            <p>{t("developing_cps_desc_7")}</p>
                          </li>
                          <li>
                            <p>{t("developing_cps_desc_8")}</p>
                          </li>
                          <li>
                            <p>{t("developing_cps_desc_9")}</p>
                          </li>
                        </ul>
                        <h5>{t("developing_bcbc")}</h5>
                        <ul>
                          <li>
                            <p>{t("developing_bcbc_desc_1")}</p>
                          </li>
                          <li>
                            <p>{t("developing_bcbc_desc_2")}</p>
                          </li>
                          <li>
                            <p>{t("developing_bcbc_desc_3")}</p>
                          </li>
                          <li>
                            <p>{t("developing_bcbc_desc_4")}</p>
                          </li>
                        </ul>
                      </Tab.Pane>
                      <Tab.Pane eventKey="research">
                        <h4>{t("mc_dev_research_desc")}</h4>
                        <h5>{t("value_added_products")}</h5>
                        <p>{t("value_added_products_desc")}</p>
                        <h5>{t("market_research_primary")}</h5>
                        <p>{t("market_research_primary_desc")}</p>
                        <h5>{t("market_research_secondary")}</h5>
                        <p>{t("market_research_secondary_desc")}</p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="resources">
                        <h4>{t("mc_dev_resources_desc")}</h4>
                        <ul>
                          <li>
                            <p>{t("mc_dev_resources_desc_1")}</p>
                          </li>
                          <li>
                            <p>{t("mc_dev_resources_desc_2")}</p>
                          </li>
                          <li>
                            <p>{t("mc_dev_resources_desc_3")}</p>
                          </li>
                          <li>
                            <p>{t("mc_dev_resources_desc_4")}</p>
                          </li>
                          <li>
                            <p>{t("mc_dev_resources_desc_5")}</p>
                          </li>
                        </ul>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </div>
              <div className="my-5">
                <h2 className="mb-4">{t("fin_management")}</h2>
                <Tab.Container defaultActiveKey="home">
                  <div className="tab-container">
                    <Nav fill className="tab-nav">
                      <Nav.Item>
                        <Nav.Link eventKey="home">
                          {t("fin_management")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="training">{t("training")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="resources">
                          {t("resources")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="home">
                        <Container>
                          <Row>
                            <Col lg={4}>
                              <Image
                                src={finance}
                                rounded
                                className="tab-image"
                              />
                            </Col>
                            <Col lg={8}>
                              <p>{t("fin_management_desc")}</p>
                            </Col>
                          </Row>
                        </Container>
                      </Tab.Pane>
                      <Tab.Pane eventKey="training">
                        <h4>{t("fin_management_training_desc")}</h4>
                        <h5>{t("fin_literacy")}</h5>
                        <ul>
                          <li>
                            <p>{t("fin_literacy_desc_1")}</p>
                          </li>
                          <li>
                            <p>{t("fin_literacy_desc_2")}</p>
                          </li>
                          <li>
                            <p>{t("fin_literacy_desc_3")}</p>
                          </li>
                          <li>
                            <p>{t("fin_literacy_desc_4")}</p>
                          </li>
                          <li>
                            <p>{t("fin_literacy_desc_5")}</p>
                          </li>
                          <li>
                            <p>{t("fin_literacy_desc_6")}</p>
                          </li>
                        </ul>
                      </Tab.Pane>
                      <Tab.Pane eventKey="resources">
                        <h4>{t("fin_management_resources_desc")}</h4>
                        <ul>
                          <li>
                            <p>{t("fin_management_resources_desc_1")}</p>
                          </li>
                        </ul>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </div>
            </Container>
          </Layout>
        )}
      </I18n>
    )
  }
}

export default withI18next()(McticServices)

export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`
